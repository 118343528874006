import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Center,
  Box,
  Text,
  ModalFooter,
  Button,
  Input,
  FormControl,
  VisuallyHidden,
  FormLabel,
  FormErrorMessage,
  Flex,
  propNames,
} from "@chakra-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Image from "next/legacy/image";
import Link from "next/link";
import { Dispatch, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import { checkSignIn, SignInFormType } from "../../../../utils/pelicanAccount";
import SignInForm from "./SignInForm";

interface Props {
  onClose: () => void;
  onOpenResetModal: () => void;
  isOpen: boolean;
  setAlertModalData: Dispatch<SetStateAction<AlertModalProps>>;
  openAlertModal: () => void;
  closeAlertModal: () => void;
  globalState: GlobalState;
  setGlobalState: Dispatch<SetStateAction<GlobalState>>;
}

const SignedInAlertButtons = ({ onClose }: { onClose: () => void; }): JSX.Element => {
  return (
    <Flex direction="row" gap="3">
      <Button
        colorScheme="brand.darkgray"
        onClick={onClose}
      >
        Continue Browsing
      </Button>
      <Link href="https://account.ppm.org/" passHref legacyBehavior>
        <Button
          as="a"
          colorScheme="brand.green"
          sx={{
            '&:hover': { textDecoration: 'none' }
          }}
        >
          Go To Pelican Account
        </Button>
      </Link>
    </Flex>
  );
}

const SignInModal = (props: Props): JSX.Element => {

  const handleHavingTroubleClick = () => {
    props.onClose();
    setTimeout(() => {
      props.onOpenResetModal();
    }, 200)
  }

  const signedIn = () => {
    props.setAlertModalData({
      icon: ['far', 'circle-check'] as IconProp,
      iconColor: 'brand.green.600',
      title: 'You Are Signed In',
      content: `Would you like to go to your Pelican Account, or continue browsing?`,
      buttons: <SignedInAlertButtons onClose={props.closeAlertModal} />
    });

    props.onClose();
    props.openAlertModal();
  }

  const onError = () => {
    props.setAlertModalData({
      icon: ['fas', 'xmark'] as IconProp,
      iconColor: 'red.400',
      title: 'Oops!',
      content: 'Sorry, but there was an error submitting your information. You can wait and try again or try logging in to your account directly using the "Go to My Account" button below',
      buttons: (
        <Flex direction="row" gap="3">
          <Button
            onClick={props.closeAlertModal}
            colorScheme="brand.darkgray">
            Close
          </Button>
          <Link href="https://account.ppm.org/" passHref legacyBehavior>
            <Button as="a"
              colorScheme="brand.green"
              sx={{
                '&:hover': { textDecoration: 'none' }
              }}>
              Go to My Account
            </Button>
          </Link>
        </Flex>)
    });

    props.openAlertModal();
  }


  return (
    <Modal onClose={props.onClose} isOpen={props.isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
      
          <ModalHeader>
            <Center w="100%" flexDirection="column" gap="5">
              <Image src={'/images/praying-pelican-logo-signIn.png'} alt="Praying Pelican Missions Logo" width={2355 / 10} height={988 / 10} />
              <Text as="h2" fontSize="2xl">
                Sign In
              </Text>
            </Center>
          </ModalHeader>
          <ModalBody>
          <SignInForm signedIn={signedIn} onError={onError} globalState={props.globalState} setGlobalState={props.setGlobalState} blok={{}}></SignInForm>
          </ModalBody>
          <ModalFooter justifyContent="center" flexDirection={"column"} gap="3">
          <Button
                variant="link"
                onClick={handleHavingTroubleClick}
            >
                Having trouble signing in?
            </Button>
          </ModalFooter>

      </ModalContent>
    </Modal>
  )
}

export default SignInModal;