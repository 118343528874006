import { Box, ChakraComponent, Flex, GridItem, GridItemProps, InteractivityProps, LinkBox, LinkOverlay, ResponsiveValue, Tag, Text } from "@chakra-ui/react"
import Image from "next/legacy/image"
import { Key } from "react"
import { ConditionalWrapper } from "../misc/ConditionalWrapper"
import { LocationLink, LocationLinkType } from "../locations/LocationLink"
import Link from "next/link"
import { DisasterResposneLink } from "../disaster/DisasterResponseLink"

export interface CardProps {
    imageSrc: string,
    imageAlt?: string,
    imageBlurDataUrl?: string,
    children?: React.ReactNode,
    tagLine: React.ReactNode,
    tagAlignment?: "start" | "end",
    titleText: React.ReactNode,
    subText: React.ReactNode,
    key: Key,
    gridItem?: GridItemProps,
    linkHref?: string,
    locationLink?: StoryblokLocation,
    locationLinkType?: LocationLinkType
    height?: string;
    disasterLink?: DisasterResponse


}



export const Card = ({ imageSrc, imageAlt, imageBlurDataUrl, tagLine, tagAlignment = "start",titleText, subText, children, gridItem, linkHref, locationLink, locationLinkType, height, disasterLink }: CardProps) => {

    let placeholder: "blur" | "empty" = "empty";

    if (imageBlurDataUrl) placeholder = "blur";

    if (!height) height = "250px"

    return (
        <GridItem
            position="relative"
            overflow={'hidden'}
            rounded={'xl'}
            height={height}
            boxShadow="dark-lg"
            {...gridItem}
        >
            <ConditionalWrapper condition={linkHref ? true : false}
                wrapper={children => <LinkBox h="full"><Link href={linkHref} legacyBehavior><LinkOverlay>{children}</LinkOverlay></Link></LinkBox>} >
                <ConditionalWrapper condition={locationLink ? true : false}
                    wrapper={children => <LinkBox h="full"><LocationLink location={locationLink} linkType={locationLinkType}><LinkOverlay>{children}</LinkOverlay></LocationLink></LinkBox>}>
                          <ConditionalWrapper condition={disasterLink ? true : false} wrapper={children => <LinkBox h="full"><DisasterResposneLink disaster={disasterLink}><LinkOverlay>{children}</LinkOverlay></DisasterResposneLink></LinkBox>}>
                    <>
                        <Image
                            unoptimized={imageSrc.includes("a.storyblok.com")}
                            src={imageSrc}
                            alt={imageAlt}
                            objectFit='cover'
                            objectPosition={'center center'}
                            layout="fill"
                            blurDataURL={imageBlurDataUrl}
                            placeholder={placeholder}
                        />
                        <Box
                            position="absolute"
                            top="50%"
                            left="50%"
                            transform="translate(-50%, -50%)"
                            padding="3"
                            width="100%"
                            height="100%"
                            bgColor="blackAlpha.400"
                            rounded={'xl'}
                            overflow="hidden"
                        >
                            <Flex
                                direction="column"
                                justifyContent="space-between"
                                rounded={'xl'}
                                overflow="hidden"
                                width="100%"
                                height="100%"
                                padding="3"
                                transition="background-color 0.1s"
                                _hover={{
                                    bgColor: 'blackAlpha.500'
                                }}
                            >

                          
                                <Flex maxWidth={'auto'} flexDirection="row"  justifyContent={tagAlignment}>
                                    {
                                        tagLine && <Tag  variant="solid" bgColor="blackAlpha.800" textTransform={'uppercase'} fontWeight="bold" fontSize="xs">
                                            {tagLine}
                                        </Tag>
                                    }
                                </Flex>
                             
                                <Box>
                                    <Text
                                        color="white"
                                        textTransform="uppercase"
                                        letterSpacing={'tighter'}
                                        fontWeight="bold"
                                        fontSize="xs"
                                        marginBottom="1"
                                    >
                                        {titleText}
                                    </Text>
                                    <Text
                                        color="white"
                                        textTransform="uppercase"
                                        fontSize="lg"
                                        marginBottom="0"
                                    >
                                        {subText}
                                    </Text>

                                </Box>
                                {children}
                            </Flex>

                        </Box>
                    </>
                    </ConditionalWrapper>
                </ConditionalWrapper>
            </ConditionalWrapper>
        </GridItem>
    );
}


