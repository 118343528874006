import { LinkBox, LinkOverlay, Flex, Text, Box, ResponsiveValue } from "@chakra-ui/react"
import Link from "next/link"
import Image, { StaticImageData } from "next/legacy/image"
import { Property } from "csstype";

export interface SubNavPrimaryLinkProps {
href: string,
title: string,
text: string,
image?: StaticImageData,
imageAlt?: string,
imageOverlayColor?: string,
display?: ResponsiveValue<Property.Display>
h?: string
objectPosition?: string
}
export const SubNavPrimaryLink = ({href, title, text, image, imageAlt, display = "", h = "240px", imageOverlayColor = "blackAlpha.600", objectPosition= 'center center' }: SubNavPrimaryLinkProps) => {

    return (
        <LinkBox h={h} maxH="240px" rounded="xl" overflow="hidden" display={display} className="main-nav-link">
          <Link href={href} passHref legacyBehavior>
            <LinkOverlay
              _hover={{ textDecoration: 'none' }}
            >
             <Image
              src={image}
              alt={imageAlt}
              objectFit='cover'
              objectPosition={objectPosition}
              layout="fill"
            />

          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            width="100%"
            height="100%"
            rounded={'xl'}
            overflow="hidden"
          >
            <Flex
              justify={'center'}
              direction="column"
              bgColor={imageOverlayColor}
            
              h="100%"
              w='full'
              p="5"
              borderRadius={'lg'}
            >
                <Text
                  textStyle={'subNavTitle'}
                  color="white"
                  fontSize="18px"
                  _hover={{ textDecoration: 'none' }}
                  textAlign={{ base: "center", lg: 'left' }}
                >
                  {title}
                </Text>
                <Text
                  textStyle={'subNavDescription'}
                  color="white"
                  fontSize="16px"
                  textAlign={{ base: "center", lg: 'left' }}
                  mb="0"
                >
                  {text}
                </Text>
              </Flex>
            </Box>
          </LinkOverlay>
        </Link>
      </LinkBox>
    );
}