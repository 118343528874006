import {
  Box,
  Flex,
  IconButton,
  Collapse,
  useDisclosure,
  Container,
  useMediaQuery,
} from '@chakra-ui/react';

import Link from 'next/link';


import Image from "next/legacy/image";
import TopBarContainer from './TopBar';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import ResourcesSubNav from './subnavs/ResourcesSubNav';
import MissionTripsSubNav from './subnavs/MissionTripsSubNav';
import TripJournalsSubNav from './subnavs/TripJournalsSubNav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';
import DisasterResponseSubNav from './subnavs/DisasterResponseSubNav';
import Ppm365SubNav from './subnavs/Ppm365SubNav';
import React from 'react';
import SearchSubNav from './subnavs/SearchSubNav';

const debounce = require('debounce')

interface NavigationProps {
  globalState: GlobalState;
  setGlobalState: Dispatch<SetStateAction<GlobalState>>;
}



export const NavigationContext = React.createContext({ topBarVisible: true })

export default function Navigation({ globalState, setGlobalState }: NavigationProps): JSX.Element {
  const microcopy = globalState.microcopy;

  const { isOpen, onToggle, onClose } = useDisclosure();
  const [topBarVisible, setTopBarVisible] = useState<boolean>(true);

  const handleScroll = debounce(() => {
    const currentScrollPos = window.pageYOffset;
    setTopBarVisible(currentScrollPos < 100);
  }, 25);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [topBarVisible, handleScroll]);

  const [isLgSize] = useMediaQuery('(min-width: 62em)')
  
  const navigationItems: Array<NavItem> = [
    {
      label: microcopy['mission-trips'],
      hasSubNav: true,
      subNav: <MissionTripsSubNav globalState={globalState} setGlobalState={setGlobalState} key={microcopy['mission-trips']} label={microcopy['mission-trips']} />
    },
    {
      label: "Church Sponsorship",
      hasSubNav: true,
      subNav: <Ppm365SubNav globalState={globalState} setGlobalState={setGlobalState} key={"Church Sponsorship"} label={"Church Sponsorship"} />
    },
    // {
    //   label: microcopy['resources'],
    //   hasSubNav: true,
    //   subNav: <ResourcesSubNav globalState={globalState} setGlobalState={setGlobalState} key={microcopy['resources']} label={microcopy['resources']} />
    // },
    // {
    //   label: microcopy['contact-us'],
    //   hasSubNav: true,
    //   subNav: <ContactUsSubNav globalState={globalState} key={microcopy['contact-us']} label={microcopy['contact-us']} />
    // },
    {
      label: 'Disaster Response',
      hasSubNav: globalState.disasters.length > 0,
      subNav: <DisasterResponseSubNav globalState={globalState} setGlobalState={setGlobalState} key={'Disaster Response'} label={'Disaster Response'} />,
      icon: <FontAwesomeIcon icon={['fas', 'circle-exclamation']} style={{ marginRight: '5px' }} />,
      href: '/disaster-response'
    },
    {
      label: microcopy['trip-journals'],
      hasSubNav: true,
      subNav: <TripJournalsSubNav globalState={globalState} setGlobalState={setGlobalState} key={microcopy['trip-journals']} label={microcopy['trip-journals']} />
    },
    {
      label: 'search',
      hasSubNav: true,
      hasIcon: true,
      icon: <FontAwesomeIcon 
        icon={['fas', 'magnifying-glass']} 
        fontSize={'18px'} 
        data-nav-item={'search'}
        className="main-nav-item"></FontAwesomeIcon>,
      subNav: <SearchSubNav globalState={globalState} setGlobalState={setGlobalState} key={'search'} label={'search'} />,
      isSearchNavItem: true
    }
  ];

  return (
    <NavigationContext.Provider value={{ topBarVisible: topBarVisible }}>
      <Box zIndex="1000">
        <Flex position={'relative'}>
          <TopBarContainer visible={topBarVisible} globalState={globalState} setGlobalState={setGlobalState} />
        </Flex>

        <Flex
          bg={{ base: 'white', lg: 'whiteAlpha.800' }}
          backdropFilter={'blur(5px)'}
          color={'brand.darkgray.600'}
          minH={'60px'}
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderBottom={{ lg: 1 }}
          borderStyle={'solid'}
          borderColor={'gray.200'}
          position={{ lg: 'fixed' }}
          width={'100%'}
          top={{ base: '0px', lg: topBarVisible ? '50px' : '0px' }}
          transition={'top 0.6s'}
          zIndex="1000"
          align={'center'}
          boxShadow={'md'}
        >

          <Container maxWidth="container.xl" flexDirection={'column'}>
            <Flex flex={{ base: 1 }} justify={{ base: 'start' }} position="relative">
              <Link href="/" passHref style={{ fontSize: 0 }}>

                <Image
                  src={'/images/praying-pelican-logo.png'}
                  alt="Praying Pelican Missions Logo"
                  width={2355 / 15}
                  height={988 / 15}
                />

              </Link>

              {isLgSize ? <DesktopNav key={'desktop-nav'} navigationItems={navigationItems} /> : null}
            </Flex>
          </Container>

          {
            !isLgSize ? <Flex
              flex={{ base: 1, lg: 'auto' }}
              ml={{ base: -2 }}
              display={{ base: 'flex', lg: 'none' }}>
              <IconButton
                onClick={onToggle}
                icon={
                  isOpen ?
                    <FontAwesomeIcon icon={['fas', 'xmark']} /> :
                    <FontAwesomeIcon icon={['fas', 'bars']} />
                }
                variant={'ghost'}
                aria-label={'Toggle Navigation'}
              />
            </Flex>
              : null
          }

        </Flex>

        {!isLgSize ?
          <Collapse in={isOpen} animateOpacity>
            <MobileNav navigationItems={navigationItems} globalState={globalState} setGlobalState={setGlobalState} toggleNav={onClose} />
          </Collapse>
          : null
        }
      </Box>
    </NavigationContext.Provider>
  );
}

export interface NavItem {
  label: string;
  href?: string;
  hasSubNav?: boolean;
  subNav?: JSX.Element;
  icon?: JSX.Element;
  hasIcon?: boolean;
  openNavItem?: string;
  isOpen?: boolean;
  handleNavClick?: () => void;
  isSearchNavItem?: boolean;
}

