import { Box, Button, Divider, Flex, Link, LinkBox, LinkOverlay, List, ListItem, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "next/legacy/image";
import NextLink from 'next/link';
import { useEffect, useRef, useState } from "react";
import { colorToVar } from "../../../../utils/misc";
import { Card } from "../../Card";
import SubNavGrid, { SubNavProps } from "../SubNavGrid";

const navLinks = [
  {
    title: 'Mission Trip Info',
    description: 'Get all the information you need about our mission trips and what your group can expect as they serve on mission with Praying Pelican.',
    href: '/mission-trips',
  },
  {
    title: 'Youth Group Mission Trips',
    description: 'Deepen your youth mission trip by doing ministry built on genuine partnerships, long term relationships, and sustainable ministry.',
    href: '/youth-group-mission-trips',
  },
  {
    title: 'College Mission Trips',
    description: 'College students are looking for a place to make a meaningful impact. Amplify that impact by partnering alongside a local church.',
    href: '/college-mission-trips',
  },
  {
    title: 'Adult Mission Trips',
    description: 'Our customizable mission trips allow each person on your team of adults to find a place to serve, regardless of their interests or skills.',
    href: '/adult-mission-trips',
  },
];

const MissionTripsSubNav = ({ openNavItem, label, globalState }: SubNavProps):JSX.Element => {
  const [activeFilter, setActiveFilter] = useState('all');
  const [filteredLocations, setFilteredLocations] = useState<StoryblokLocation[]>(globalState.locations.sort((a,b) => a.name.localeCompare(b.name)));

  const topOfCards = useRef<HTMLDivElement>();

  useEffect(() => {
    const newFilteredLocations = globalState.locations.filter(location => {
      if (activeFilter === 'all') {
        return true;
      }

      if (activeFilter === 'domestic') {
        return location.rootsData.domestic === 1;
      }

      if (activeFilter === 'international') {
        return location.rootsData.domestic === 0;
      }
      
      return location.region.replaceAll(' ', '').toLowerCase() === activeFilter;

    }).sort((a,b) => {
      const aNameWithoutThe = a.name.indexOf('The ') === 0 ? a.name.replace('The ', '') : a.name;
      const bNameWithoutThe = b.name.indexOf('The ') === 0 ? b.name.replace('The ', '') : b.name;
      
      return aNameWithoutThe.localeCompare(bNameWithoutThe);
    });

    setFilteredLocations(newFilteredLocations);
  }, [activeFilter, globalState.locations])

  const regions: string[] = globalState.locations
    .filter((location) => location.region !== 'USA')
    .map(location => location.region)
    .sort((a, b) => a.localeCompare(b));

  const regionFilters = [
    {name: 'All Mission Trip Locations', filter: 'all'},
    {name: 'International Mission Trips', filter: 'international'},
    {name: 'USA Mission Trips', filter: 'domestic'},
    ...Array.from(new Set(regions))
      .map(region => ({
        name: `${region} Mission Trips`,
        filter: region.replaceAll(' ', '').toLowerCase()
      }))
  ];

  
  const regionImages = {
    'all':            { type: 'icon',  top: '12px', left: '12px', icon: <FontAwesomeIcon icon="globe-americas" size="2x" /> },
    'international':  { type: 'icon',  top: '12px', left: '12px', icon: <FontAwesomeIcon icon="plane" size="2x" /> },
    'domestic':       { type: 'image', top: '6px', left: '-1px',  w: '60px', h: '50px', src: 'https://a.storyblok.com/f/160992/x/7718ef93f6/us_light.svg' },
    'centralamerica': { type: 'image', top: '13px', left: '6px',  w: '45px', h: '30px', src: 'https://a.storyblok.com/f/160992/x/9a5a2b41ab/central_america_light.svg' },
    'caribbean':      { type: 'image', top: '14px', left: '3px', w: '50px', h: '24px', src: '/images/regions/caribbean.png' },
    'africa':         { type: 'image', top: '0px', left: '-1px', w: '80px', h: '95px', src: 'https://a.storyblok.com/f/160992/x/f0f5eda95c/africa_light.svg' },
    'southamerica':   { type: 'image', top: '4px', left: '8px', w: '40px', h: '55px', src: 'https://a.storyblok.com/f/160992/x/3f44c5a313/south_america_light.svg' },
    'middleeast':     { type: 'image', top: '-6px', left: '9px', w: '40px', h: '70px', src: 'https://a.storyblok.com/f/160992/x/4a6fe5240c/middle_east_light.svg' },
    'europe':         { type: 'image', top: '8px', left: '0px', w: '70px', h: '50px', src: 'https://a.storyblok.com/f/160992/x/2d41e44447/europe_light.svg' },
    'canada':         { type: 'image', top: '6px', left: '-1px', w: '80px', h: '60px', src: 'https://a.storyblok.com/f/160992/500x500/53e88296f2/canada_light.png' }
  }

  const scrollToCards = () => topOfCards.current ? topOfCards.current.scrollIntoView({ behavior: 'auto', block: 'end', inline: 'nearest' }) : '';

  return (
    <SubNavGrid
      templateColumns="3fr 3fr 2fr"
      gap={3}
      visible={openNavItem === label}
      openNavItem={openNavItem}
    >
      <Box display={{ base: 'inherit', lg: 'none' }} w="full" bg="transparent">
        <NextLink href="/mission-trip-locations" passHref legacyBehavior>
          <Button
            as="a"
            _hover={{textDecoration: 'none'}}
            variant="solid"
            colorScheme="brand.green"
          >
            View All Mission Trip Locations
          </Button>
        </NextLink>
      </Box>

      <List flexDirection={'column'} gap="1px" display={{base: 'none', lg: 'flex'}}>
        {
          regionFilters.map((region, index) => 
            <RegionFilter 
              key={index} 
              region={region} 
              regionImages={regionImages} 
              activeFilter={activeFilter} 
              setActiveFilter={() => {
                setActiveFilter(region.filter);
                scrollToCards();
              }
            } 
            />
          )
        }
      </List>
      
      <Box h={`${60 * 10 + 8}px`} overflowY="auto" display={{ base: 'none', lg: 'block' }} pr={2}>

        <div ref={topOfCards}></div>
        <SimpleGrid columns={{ base: 1, lg: 1 }} gridGap={5} >
          {
          filteredLocations.map((location, index) => {
            let linkHref;

            const locationUrlArray = location?.pageLink.cachedUrl.split('/');
            const locationUrlSlug = locationUrlArray[locationUrlArray.length - 1];

            if (locationUrlArray.length > 2 && locationUrlArray[1] === 'es') {
              linkHref = `/${locationUrlArray[1]}/mission-trips/${locationUrlSlug}`;
            } else {
              linkHref = `/mission-trips/${locationUrlSlug}`;
            }

            return (
              <Card key={index} 
              locationLink={location}
              tagLine={<>From&nbsp;<Text as="span" color="brand.green.600">${location?.rootsData.pricedFrom.toLocaleString()}</Text></>}
              tagAlignment="end"
              titleText= {location.region}
              subText={location.name}
              imageSrc={`${location?.image.filename}/m/400x100/smart`}
              imageAlt={location?.name}
              imageBlurDataUrl={`${location?.image.filename}/m/10x0`}
              height="110px"
              gridItem={{boxShadow: "none"}}></Card>
            )
          })}
        </SimpleGrid>
      </Box>
      
      <Stack 
        justifyContent={'space-between'} 
        display='flex' 
        maxH={{base: 'auto', lg: `${60 * 10 + 8}px`}}
        p="5"
        borderWidth={'1px'}
        borderColor={'brand.darkgray.600'}
        borderRadius="lg"
        bgColor="blackAlpha.50"
      >
        {navLinks.map((link) => (
          <Box key={link.title}>
            <NextLink href={link.href} passHref legacyBehavior>
              <Link textStyle={'subNavTitle'} display="flex" gap="1" alignItems={'center'}>
                {link.title} <FontAwesomeIcon icon="angle-right" />
              </Link>
            </NextLink>
            <Text textStyle={'subNavDescription'}>
              {link.description}
            </Text>
          </Box>
        ))}
        <Flex justifyContent={'center'}> 
          <NextLink href="/mission-trip-dates" passHref legacyBehavior>
            <Button 
              size="sm" 
              colorScheme={'brand.green'} 
              variant={'solid'} 
              leftIcon={<FontAwesomeIcon icon="search" />} 
              w="100%"
            >
              FIND YOUR MISSION TRIP
            </Button>
          </NextLink>
        </Flex>
      </Stack>
    </SubNavGrid>
  );
}

const RegionFilter = ({ region, regionImages, activeFilter, setActiveFilter }) => {
  const isActive = activeFilter === region.filter;
  const bgColor = isActive ? colorToVar('brand.green', 600) : colorToVar('brand.darkgray', 600);
  
  return (
    <ListItem
      onClick={() => setActiveFilter(region.filter)}
      h="60px"
      display="flex"
      flexDirection="row"
      alignItems={'center'}
      bgColor={bgColor}
      overflow="visible"
      ml="5"
      mr="5"
      position="relative"
      justifyContent={'space-between'}
      cursor={'pointer'}
    >
      <Box 
        position="absolute"
        left="-10"
        bgColor={bgColor}
        borderRadius="full"
        borderColor="white"
        borderWidth="4px"
        h="65px"
        w="65px"
      >
        {
          regionImages[region.filter] && regionImages[region.filter].type === 'image' &&
          <Box
            top={regionImages[region.filter].top}
            left={regionImages[region.filter].left}
            position="relative"
          >
            <Image
            unoptimized={regionImages[region.filter].src.includes('a.storyblok.com')}
              src={regionImages[region.filter].src}
              width={regionImages[region.filter].w}
              height={regionImages[region.filter].h}
              alt={region.name}
            />
          </Box>
        }
        
        {
          regionImages[region.filter] && regionImages[region.filter].type === 'icon' &&
          <Box
            top={regionImages[region.filter].top}
            left={regionImages[region.filter].left}
            position="relative"
            textColor={'white'}
          >
            {regionImages[region.filter].icon}
          </Box>
        }
      </Box>

      <Box ml="10" flexGrow={1} lineHeight={'0'}>
        <Text 
          as="span" 
          whiteSpace={'nowrap'}
          textColor={"white"}
          fontWeight={'bold'}
          lineHeight={'0'}
        >
          {region.name}
        </Text>
      </Box>

      <Box 
        w='25px' 
        h="full"
        position="absolute"
        right="-6"
        bgGradient={
          isActive
          ? `linear-gradient(to bottom left,transparent 49.6%, ${bgColor} 50%),linear-gradient(to top left,transparent 49.6%,${bgColor} 50%)`
          : 'linear-gradient(to bottom left,transparent 49.6%, white 50%),linear-gradient(to top left,transparent 49.6%,white 50%)'
        }
        backgroundRepeat="no-repeat"
        backgroundSize="100% 50.8%"
        backgroundPosition="top right,bottom left"
      />
    </ListItem>
  )

}


export default MissionTripsSubNav;