import { Box, Flex, GridItem, Link, SimpleGrid, Text } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NextLink from 'next/link';
import ContactForm from "../../../misc/contact-form/ContactForm";
import SubNavGrid, { SubNavProps } from "../SubNavGrid";

const ContactUsSubNav = ({ openNavItem, label, globalState, setGlobalState }:SubNavProps):JSX.Element => (
  <Box id="nav-contact-form">
    <SubNavGrid
      visible={openNavItem === label}
      openNavItem={openNavItem}
      templateColumns={'2fr 1fr'}
    >
      <Flex justifyContent={'center'} alignItems={'center'} direction="column">
        <Box w="full" px="10">
          <SimpleGrid columns={{ base: 1, md: 4 }}>
            {
              <GridItem colSpan={1}>&nbsp;</GridItem>
            }

            <GridItem colSpan={3}>
              <Text as="h2" fontSize="xl" mb="4" fontWeight="bold" textAlign={'center'}>
                Request Information
              </Text>
            </GridItem>
          </SimpleGrid>
        </Box>

        <Box w="100%" paddingX="10">
          <ContactForm blok={{ colorScheme: 'light', showOptionalToggle: true, showLabels: true, submitText: 'Request Info' }} globalState={globalState} setGlobalState={setGlobalState} />
        </Box>
      </Flex>

      
      <ContactUsStack />
    </SubNavGrid>
  </Box>
)

export const ContactUsStack = ({textColor}:{textColor?: string}) => {
  return (
    <Flex direction={'column'} gap={4} justifyContent={'space-evenly'}>
      <Flex justifyContent={'center'} alignItems={'center'} direction={'column'}>
        <FontAwesomeIcon icon={['fas', 'envelope']} style={{ color: `var(--chakra-colors-${(textColor ?? 'brand.darkgray.600').replaceAll('.', '-')})` }} />
        <Text textTransform={'uppercase'} color={textColor ?? 'brand.darkgray.600'}>Email</Text>
        <NextLink href={'mailto:info@ppm.org'} passHref legacyBehavior>
          <Link
            textStyle={'subNavDescription'}
            textTransform="uppercase"
            color={'brand.green.600'}
            fontWeight={'600'}
            target="_blank"
          >
            info@ppm.org
          </Link>
        </NextLink>
      </Flex>

      <Flex justifyContent={'center'} alignItems={'center'} direction={'column'}>
        <FontAwesomeIcon icon={['fas', 'phone-alt']} style={{ color: `var(--chakra-colors-${(textColor ?? 'brand.darkgray.600').replaceAll('.', '-')})` }} />
        <Text textTransform={'uppercase'} color={textColor ?? 'brand.darkgray.600'}>Phone</Text>
        <NextLink href={'tel:8887764090'} passHref legacyBehavior>
          <Link
            textStyle={'subNavDescription'}
            textTransform="uppercase"
            color={'brand.green.600'}
            fontWeight={'600'}
          >
            888.PPM.4090
          </Link>
        </NextLink>
      </Flex>

      <Flex justifyContent={'center'} alignItems={'center'} direction={'column'}>
        <FontAwesomeIcon icon={['fas', 'map-marked']} style={{ color: `var(--chakra-colors-${(textColor ?? 'brand.darkgray.600').replaceAll('.', '-')})` }} />
        <Text textTransform={'uppercase'} color={textColor ?? 'brand.darkgray.600'}>Address</Text>
        <NextLink href={'https://goo.gl/maps/HLmQuUmXZBH2'} passHref legacyBehavior>
          <Link
            textStyle={'subNavDescription'}
            textTransform="uppercase"
            color={'brand.green.600'}
            fontWeight={'600'}
            textAlign={'center'}
            target="_blank"
          >
            8011 34th Ave S, Suite 400
            <br />
            Minneapolis, MN 55425
          </Link>
        </NextLink>
      </Flex>
    </Flex>
  );
}

export default ContactUsSubNav;