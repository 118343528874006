import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Center,
  Box,
  Text,
  ModalFooter,
  Button,
  useDisclosure,
  FormControl,
  VisuallyHidden,
  FormLabel,
  Input,
  FormErrorMessage,
  Textarea,
  Divider,
  Select,
} from "@chakra-ui/react";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "next/legacy/image";
import { event } from "nextjs-google-analytics";
import { useState, Dispatch, useEffect } from "react";
import { useForm } from "react-hook-form";
import AlertModal from "../../misc/AlertModal";
import { ContactFormProps } from "./ContactForm";
import IndividualContactFormCheckboxes from "./IndividualContactFormCheckboxes";

interface IndividualContactModalProps {
  onClose: () => void;
  resetMainForm: () => void;
  isOpen: boolean;
  contactFormValues: ContactFormProps;
  globalState: GlobalState;
  setGlobalState: Dispatch<React.SetStateAction<GlobalState>>;
  dontBlockScrollOnMount?: boolean;
  contactFormType: string;
}

export interface IndividualApplicationForm extends ContactFormProps {
  timeframes: [];
  ministries: [];
  occupation: string;
  occupationOther: string;
}

const IndividualContactModal = (props: IndividualContactModalProps):JSX.Element => {
  const { register, handleSubmit, formState, reset, getValues, setValue } = useForm<IndividualApplicationForm>({
    defaultValues: {
      ...props.contactFormValues,
      timeframes:[],
      ministries:[],
      occupation:'',
      occupationOther:'',
    }
  });

  useEffect(() => {
    setValue('comments', props.contactFormValues.comments);
    setValue('email', props.contactFormValues.email);
    setValue('location', props.contactFormValues.location);
    setValue('name', props.contactFormValues.name);
    setValue('organization', props.contactFormValues.organization);
    setValue('phone', props.contactFormValues.phone);
    setValue('size', props.contactFormValues.size);
    setValue('type', props.contactFormType);
  }, [props.contactFormValues, props.contactFormType, setValue]);

  const [confirmModalData, setConfirmModalData] = useState<AlertModalProps>({
    icon: ['far', 'circle-check'] as IconProp,
    iconColor: 'brand.green.600',
    title: 'Got It!',
    content: `We have received your information, and someone will be following up with you soon. Thanks!`
  });

  const confirmModal = useDisclosure();

  const [showOtherOccupation, setShowOtherOccupation] = useState<boolean>(false);

  const handleOccupationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === 'Other') {
      setShowOtherOccupation(true);
    } else {
      setShowOtherOccupation(false);
    }
  }

  const submitIndividualContactForm = async (values) => {
    const currentPage = window ? window.location.pathname + window.location.search + window.location.hash : '';

    const submitData = {
      ...values,
      location: values.location.join('; '),
      ministries: values.ministries.join('; '),
      timeframe: values.timeframes.join('; '),
      type: props.contactFormType,
      analyticsInfo: {
        currentPage: currentPage,
        source: '-',
        medium: '-',
        campaign: '-',
        term: '-',
        content: '-',
        numVisits: 0
      }
    }

    try {
      const sendContactForm = await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/contact/individual`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(submitData)
      })

      if (sendContactForm.ok) {
        setConfirmModalData({
          icon: ['far', 'circle-check'] as IconProp,
          iconColor: 'brand.green.600',
          title: 'Got It!',
          content: 'We have received your information, and someone will be following up with you soon. Thanks!'
        });

        props.onClose();
        props.resetMainForm();
        reset();
        confirmModal.onOpen();

        event('individual_lead', {
          category: submitData.type,
          label: submitData.size
        });

      } else {
        console.error(sendContactForm);
        throw new Error('Failed to send contact form')
      }

    } catch (error) {
      console.error(error);
      setConfirmModalData({
        icon: ['fas', 'xmark'] as IconProp,
        iconColor: 'red.400',
        title: 'Oops!',
        content: 'Sorry, but there was an error submitting your information. Please try again later.'
      });
      
      confirmModal.onOpen();
    }
  }

  return (
    <>
      <AlertModal
        onClose={confirmModal.onClose}
        isOpen={confirmModal.isOpen}
        iconColor={confirmModalData.iconColor}
        icon={confirmModalData.icon}
        title={confirmModalData.title}
        content={confirmModalData.content}
        dontBlockScrollOnMount={true}
      />

      <Modal onClose={props.onClose} isOpen={props.isOpen} size="xl" blockScrollOnMount={!props.dontBlockScrollOnMount}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Center w="100%" flexDirection="column" gap="5">
              <Image src={'/images/praying-pelican-logo.png'} alt="Praying Pelican Missions Logo" width={2355 / 10} height={988 / 10}></Image>

              <Text as="h2" fontSize="3xl" textAlign={'center'}>
                Individual Trip Application
              </Text>
            </Center>
          </ModalHeader>

          <ModalBody>
            <Text as="p" textColor="brand.darkgray.600">
              As a general rule, we aren&apos;t able to accomodate groups of less than five people. But, we 
              sometimes have special trips that are available for individuals and groups of less than five.
            </Text>
            <Text as="p" textColor="brand.darkgray.600">
              If you would like to be notified when one of these trip dates becomes available, fill out the 
              short application below.
            </Text>

            <Divider my="2" />

            <form onSubmit={handleSubmit(submitIndividualContactForm)}>
              <FormLabel
                color={'brand.darkgray.600'}
                fontWeight={'bolder'}
                textAlign={'center'}
              >
                Please select all locations you are interested in:
              </FormLabel>

              <IndividualContactFormCheckboxes
                heading="DOMESTIC"
                colorScheme={'light'}
                options={
                  props.globalState.locations
                    .filter(location => location.rootsData.domestic === 1)
                    .sort((a, b) => {
                      const aNameWithoutThe = a.name.indexOf('The ') === 0 ? a.name.replace('The ', '') : a.name;
                      const bNameWithoutThe = b.name.indexOf('The ') === 0 ? b.name.replace('The ', '') : b.name;

                      return aNameWithoutThe.localeCompare(bNameWithoutThe);
                    })
                    .map(location => location.name)
                }
                name={'location'}
                register={register}
                submitting={formState.isSubmitting}
              />

              <IndividualContactFormCheckboxes
                heading="INTERNATIONAL"
                colorScheme={'light'}
                options={
                  props.globalState.locations
                    .filter(location => location.rootsData.domestic === 0)
                    .sort((a, b) => {
                      const aNameWithoutThe = a.name.indexOf('The ') === 0 ? a.name.replace('The ', '') : a.name;
                      const bNameWithoutThe = b.name.indexOf('The ') === 0 ? b.name.replace('The ', '') : b.name;

                      return aNameWithoutThe.localeCompare(bNameWithoutThe);
                    })
                    .map(location => location.name)
                }
                name={'location'}
                register={register}
                submitting={formState.isSubmitting}
              />
              

              <FormControl
                isDisabled={formState.isSubmitting}
                mb={5}
              >
                <FormLabel
                  color={'brand.darkgray.600'}
                  fontWeight={'bolder'}
                  textAlign={'center'}
                >
                  What general time frame(s) are you able to travel?
                </FormLabel>

                <IndividualContactFormCheckboxes
                  heading=""
                  colorScheme={'light'}
                  options={['Spring', 'Summer', 'Fall', 'Winter']}
                  name={'timeframes'}
                  register={register}
                  submitting={formState.isSubmitting}
                />
              </FormControl>
              
              <FormControl
                isDisabled={formState.isSubmitting}
                mb={5}
              >
                <FormLabel
                  color={'brand.darkgray.600'}
                  fontWeight={'bolder'}
                  textAlign={'center'}
                >
                  Please select ministries that you are interested or skilled in:
                </FormLabel>

                <IndividualContactFormCheckboxes
                  heading=""
                  colorScheme={'light'}
                  options={[
                    "Children's Ministry",
                    "Evangelism ",
                    "Medical",
                    "School/Teaching",
                    "Service Projects/Construction",
                    "Sports",
                    "Training/Bible Studies",
                    "Other",
                  ]}
                  name={'ministries'}
                  register={register}
                  submitting={formState.isSubmitting}
                />
              </FormControl>

              <FormControl
                borderRadius={'lg'}
                isInvalid={!!formState.errors['occupation']}
                isDisabled={formState.isSubmitting}
                mb={5}
              >
                <FormLabel textAlign="center" fontWeight="bold" textColor="brand.darkgray.600" htmlFor={'occupation'}>
                  What is your occupation?
                </FormLabel>
                <Select
                  name={'occupation'}
                  size="md"
                  rounded="lg"
                  variant="outline"
                  borderColor="brand.orange.600"
                  {...register('occupation', { required: 'Occupation is required.' })}
                  _disabled={{
                    color: 'white',
                    cursor: 'not-allowed',
                  }}
                  onChange={handleOccupationChange}
                  _hover={{}}
                  sx={{
                    _focusVisible: {
                      borderColor: 'brand.orange.600',
                      boxShadow: '0 0 0 1px var(--chakra-colors-brand-orange-600)',
                    }
                  }}
                >
                  <option value="" disabled>Select Occupation</option>
                  <option value="Business">Business</option>
                  <option value="Construction">Construction</option>
                  <option value="Customer Service">Customer Service</option>
                  <option value="General Labor">General Labor</option>
                  <option value="Healthcare">Healthcare</option>
                  <option value="Hospitality">Hospitality</option>
                  <option value="Management">Management</option>
                  <option value="Retail">Retail</option>
                  <option value="Retired">Retired</option>
                  <option value="Sales">Sales</option>
                  <option value="Student">Student</option>
                  <option value="Teacher">Teacher</option>
                  <option value="Technology">Technology</option>
                  <option value="Transportation">Transportation</option>
                  <option value="Other">Other</option>
                </Select>

                <FormErrorMessage>
                  <Box textAlign="center" w="full">
                    {formState.errors['occupation']?.message}
                  </Box>
                </FormErrorMessage>
              </FormControl>
              
              {
                showOtherOccupation &&
                <FormControl
                  borderRadius={'lg'}
                  isInvalid={!!formState.errors['occupationOther']}
                  isDisabled={formState.isSubmitting}
                  mb={5}
                >
                  <FormLabel textAlign="center" fontWeight="bold" textColor="brand.darkgray.600" htmlFor={'occupationOther'}>
                    Specify Other Occupation
                  </FormLabel>

                  <Input
                    name={'occupationOther'}
                    type={'text'}
                    min={0}
                    variant="outline"
                    size={'md'}
                    layerStyle={'lightForm'}
                    placeholder={'Other Occupation'}
                    {...register('occupationOther')}
                    border="1px solid"
                    borderColor={'brand.orange.600'}
                  />

                  <FormErrorMessage>
                    <Box textAlign="center" w="full">
                      {formState.errors['occupationOther']?.message}
                    </Box>
                  </FormErrorMessage>
                </FormControl>
              }

              <FormControl
                borderRadius={'lg'}
                isInvalid={!!formState.errors['organization']}
                isDisabled={formState.isSubmitting}
                mb={5}
              >
                <FormLabel textAlign="center" fontWeight="bold" textColor="brand.darkgray.600" htmlFor={'organization'}>
                  What church do you currently attend?
                </FormLabel>

                <Input
                  name={'organization'}
                  type={'text'}
                  min={0}
                  variant="outline"
                  size={'md'}
                  layerStyle={'lightForm'}
                  placeholder={'Church Name & Town'}
                  {...register('organization', { required: 'Church is required' })}
                  border="1px solid"
                  borderColor={'brand.orange.600'}
                />

                <FormErrorMessage>
                  <Box textAlign="center" w="full">
                    {formState.errors['organization']?.message}
                  </Box>
                </FormErrorMessage>
              </FormControl>

              <Button
                type="submit"
                width="full"
                colorScheme={'brand.orange'}
                variant={'solid'}
                leftIcon={<FontAwesomeIcon icon={['fas', 'check']} />}
                disabled={formState.isSubmitting}
                mb={5}
              >
                Submit Application
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default IndividualContactModal;