import { Flex, Stack, Link as ChakraLink, Text, Icon, Collapse, Box, Button } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { NavItem } from "./Navigation";
import SignInButton from "./sign-in/SignInButton";

interface Props {
  navigationItems: NavItem[];
  globalState: GlobalState;
  setGlobalState: Dispatch<SetStateAction<GlobalState>>;
  toggleNav: () => void;
}

const MobileNav = ({ navigationItems, globalState, setGlobalState, toggleNav }:Props):JSX.Element => {
  const [openNavItem, setOpenNavItem] = useState<string | undefined>(undefined);
  const [showSelectLanguageSubNav, setShowSelectLanguageSubNav] = useState<boolean>(false);

  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeStart', handleCloseNav);

    return () => {
      router.events.off('routeChangeStart', handleCloseNav);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  const handleCloseNav = () => {
    setOpenNavItem(undefined)
    toggleNav();
  }

  useEffect(() => {
    window.addEventListener('click', handleMobileNavClick);
    return () => window.removeEventListener('click', handleMobileNavClick);
  })

  const handleMobileNavClick = (e: any) => {
    const mobileSubNav = document.getElementsByClassName('mobile-sub-nav');
    const mobileNavItems = document.getElementsByClassName('mobile-nav-item');

    let subNavClicked = false;
    let thisNavItem;

    for (let i = 0; i < mobileNavItems.length; i++) {
      if (mobileNavItems[i].contains(e.target)) {
        thisNavItem = mobileNavItems[i].getAttribute('data-nav-item');
      }
    }

    for (let i = 0; i < mobileSubNav.length; i++) {
      if (mobileSubNav[i].contains(e.target)) {
        subNavClicked = true;
      }
    }

    if (thisNavItem == 'search') {
      setTimeout(() => {
        const searchBar = document.getElementById('gsc-i-id1');
        if (searchBar) {
          searchBar.focus();
        }
      }, 300);
    }
    
    if (thisNavItem && !subNavClicked) {
      if (thisNavItem && openNavItem === thisNavItem) {
        setOpenNavItem(undefined);
      } else {
        setOpenNavItem(thisNavItem);
      }
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleSelectLanguageCLick);
    return () => window.removeEventListener('click', handleSelectLanguageCLick);
  })

  const handleSelectLanguageCLick = (e: any) => {
    const selectLanguageClicked = e.target.classList.contains('select-language-nav') || e.target.parentElement?.classList.contains('select-language-nav');
    const selectLanguageSubNavClicked: boolean = e.target.closest('.select-language-sub-nav');
    
    if (selectLanguageClicked) {
      setShowSelectLanguageSubNav(!showSelectLanguageSubNav);
    } else if (!selectLanguageSubNavClicked) {
      setShowSelectLanguageSubNav(false);
    }
  }

  return <>
    <Stack
      bg={'brand.lightgray.50'}
      w={'100%'}
      borderBottom={'1px solid'}
      borderColor={'brand.orange.600'}
      p={4}
      bgColor="white"
      display={{ lg: 'none' }}
    >
      <Link href="/mission-trip-dates" passHref legacyBehavior>
        <Button 
          as="a"
          sx={{ '&:hover': { textDecoration: 'none' } }}
          w="full"
          variant="solid"
          colorScheme="brand.green"
          leftIcon={<FontAwesomeIcon icon={['far', 'calendar']} />}
        >
          View Trip Dates
        </Button>
      </Link>

      <Link href={'/donate'} passHref legacyBehavior>
        <Button 
          as="a"
          sx={{ '&:hover': { textDecoration: 'none' } }}
          w="full"
          my="10px"
          variant="solid"
          colorScheme="brand.green"
          leftIcon={<FontAwesomeIcon icon={['fas', 'hand-holding-heart']} />}
        >
          Donate
        </Button>
      </Link>

      {
        navigationItems
          .filter(navItem => navItem.hasSubNav)
          .map((navItem, index) => (
            <MobileNavItem key={index} isOpen={navItem.label === openNavItem} {...navItem} />
          ))
      }

      <Link href={'tel:8887764090'} passHref legacyBehavior>
        <Button 
          as="a"
          sx={{ '&:hover': { textDecoration: 'none' } }}
          w="full"
          mt="10px"
          variant="outline"
          colorScheme="brand.green"
          leftIcon={<FontAwesomeIcon icon={['fas', 'phone-alt']} />}
          className="notranslate"
        >
          888.PPM.4090
        </Button>
      </Link>

      <Link href="/contact-us" passHref legacyBehavior>
        <Button 
          as="a"
          sx={{ '&:hover': { textDecoration: 'none' } }}
          w="full"
          mt="10px"
          variant="outline"
          colorScheme="brand.green"
          leftIcon={<FontAwesomeIcon icon={['fas', 'message']} />}
        >
          Contact Us
        </Button>
      </Link>

      <Link href="/about-us" passHref legacyBehavior>
        <Button 
          as="a"
          sx={{ '&:hover': { textDecoration: 'none' } }}
          w="full"
          mt="10px"
          mb="6px"
          variant="outline"
          colorScheme="brand.green"
          leftIcon={<FontAwesomeIcon icon={['fas', 'circle-info']} />}
        >
          About Us
        </Button>
      </Link>


      <Flex direction="row" justifyContent="space-evenly" pb={3} pt={3}>
        <Button
          className={'select-language-nav'}
          colorScheme={'brand.green'}
          sx={{ '&:hover': { textDecoration: 'none' } }}
          display={'flex'}
          variant={'link'}
          alignItems={'center'}
          lineHeight={1}
          leftIcon={<FontAwesomeIcon icon={['fas', 'globe']} />}
        >
          Select Language
        </Button>
      </Flex>

      <SignInButton 
        type="mobile"
        globalState={globalState}
        setGlobalState={setGlobalState}
      />
    </Stack>
  </>;
};

const MobileNavItem = ({ label, href, subNav, hasIcon, icon, hasSubNav, isOpen }: NavItem) => {
  if (hasSubNav) {
    return (
      <Stack
        spacing={4}
        data-nav-item={hasSubNav ? label : null}
        className="mobile-nav-item"
        data-active={isOpen ? true : null}
      >
        <Flex
          py={2}
          as={ChakraLink}
          href={!hasSubNav && href ? href : '#'}
          justify={'space-between'}
          align={'center'}
          data-active={isOpen ? true : null}
          _active={{ color: 'brand.orange.600' }}
          _hover={{
            color: 'brand.orange.600',
            textDecoration: 'none',
          }}
        >
          <Text
            fontWeight={600}
            fontSize={'md'}
            textTransform={'uppercase'}
            color={'brand.darkgray.600'}
            data-active={isOpen ? true : null}
            _active={{ color: 'brand.orange.600' }}
            _hover={{
              color: 'brand.orange.600',
            }}
          >
            {label}
          </Text>
          {hasSubNav && (
            <FontAwesomeIcon 
              icon={['fas', 'chevron-down']}
              style={{ 
                transition: 'all .25s ease-in-out',
                transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)'
              }}
            />
          )}
        </Flex>

        <Collapse
          in={isOpen}
          animateOpacity
          className="mobile-sub-nav"
        >
          {subNav}
        </Collapse>
      </Stack>
    )
  } else {
    return (
      <Flex
        alignItems={'center'}
        gap="2"
        fontSize={'sm'}
        textTransform={'uppercase'}
        fontWeight={'bold'}
        color="brand.darkgray.600"
        alignContent={'center'}
        _hover={{ color: 'brand.orange.600' }}
      >

        {hasIcon && icon}
        <Link href={href} passHref legacyBehavior>
          <ChakraLink
            textDecoration={'none'}
            _hover={{ textDecoration: 'none' }}
          >
            {label}
          </ChakraLink>
        </Link>
      </Flex>
    );
  }
};

export default MobileNav;